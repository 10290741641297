// @ts-nocheck
import { Map } from "immutable";
import {
  calculateRailingAmount,
  roundToHundreth,
  roundToNearestInch,
} from "..";
import { getRunSettings, mergeSettings } from "../../utils";
import { getPosts } from "../../utils/getPosts";
import {
  getRailDistance,
  getTotalRailDistanceOfStairsRun,
} from "../RunItemList";

export function calculateOverages(itemList, inventory, state, project) {
  const screws = screwOverages();
  const nuts = allNutsOverages();
  const washers = allWashersOverages();
  const quickNuts = quickNutOverages();
  const tensioners = tensionersOverages();
  const surfaceMount = surfaceMountOverages();
  const stairWashers = stairWashersOverages();
  const passivationSpray = passivationSprayOverage();
  const grommetsPVC = grommetsPVCOverage();
  const lagBolts = lagsBoltsOverage();
  const tapcons = tapconsOverage();
  const drillBits = drillBitOverage();
  const apexBit = apexBitOverage();
  const brushOnTape = brushOnTapeOverage();
  const plasticScrewCovers = plasticScrewCoversOverage();
  const fittingsSleeve = fittingsSleevesOverage();
  const p2pBrackets = p2pBracketsOverage();
  const aluminumSplice = aluminumSpliceOverage();
  const basePlateBlackSleeves = basePlateBlackSleevesOverage();
  const passivationService = passivationServiceOverage();
  const blackOxide = blackOxideOverrages();

  const allRules = mergeObjects(
    screws,
    nuts,
    washers,
    quickNuts,
    tensioners,
    surfaceMount,
    stairWashers,
    passivationSpray,
    grommetsPVC,
    lagBolts,
    tapcons,
    drillBits,
    apexBit,
    brushOnTape,
    plasticScrewCovers,
    fittingsSleeve,
    p2pBrackets,
    aluminumSplice,
    basePlateBlackSleeves,
    passivationService,
    blackOxide
  );

  let totalCable = null;
  let totalPosts = null;

  const firstCanvas = project?.canvases?.first()?.id;

  Object.values(itemList).forEach((item) => {
    if (allRules[item.upc]) {
      const rule = allRules[item.upc];

      if (rule.type === "percentage") {
        item.quantity = Math.ceil(item.quantity * (1 + rule.rule));
        item.total = roundToHundreth(item.quantity * item.price);
      }

      if (rule.type === "percentageOfUnit") {
        item.quantity =
          Math.ceil(Math.ceil(item.quantity / rule.unit) * (1 + rule.rule)) *
          rule.unit;
        item.total = roundToHundreth(item.quantity * item.price);
      }

      if (rule.type === "passivationService") {
        // For now passivation is calculated not as an overrage but as a part of the price list.
        // This allows for prices to be overriden.
      }

      if (rule.type === "basedOnCount") {
        const rem = Math.floor(item.quantity / rule.rule.every);

        item.quantity = item.quantity + rule.rule.add * rem;
        item.total = roundToHundreth(item.quantity * item.price);
      }

      if (rule.type === "basedOnCountAfter") {
        if (item.quantity <= rule.rule.after) {
          return;
        }

        const rem = Math.floor(
          (item.quantity - rule.rule.after) / rule.rule.every
        );

        item.quantity = item.quantity + rule.rule.add * rem;
        item.total = roundToHundreth(item.quantity * item.price);
      }

      if (rule.type === "basedOnPostsCount") {
        if (!totalPosts) {
          totalPosts = getTotalPosts(project);
        }

        if (drillBits[item.upc] && item.quantity) {
          item.quantity = Math.floor(item.quantity);
        }

        // Only add calculation for one canvas.
        if (state.id === firstCanvas) {
          const rem = Math.floor(totalPosts / rule.rule.every);

          item.quantity = item.quantity + rule.rule.add * rem;
          item.total = roundToHundreth(item.quantity * item.price);
        }
      }

      if (rule.type === "basedOnTotalCable") {
        if (!totalCable) {
          totalCable = totalCableFootage(project);
        }

        // Only add calculation for one canvas.
        if (state.id === firstCanvas) {
          const rem = Math.floor(totalCable / rule.rule.every);

          item.quantity = item.quantity + rule.rule.add * rem;
          item.total = roundToHundreth(item.quantity * item.price);
        }
      }
    }
  });

  return itemList;
}

function mergeObjects(...objects) {
  let merged = {};

  for (let object of objects) {
    merged = { ...merged, ...object };
  }

  return merged;
}

function screwOverages() {
  return {
    662187020471: { type: "percentage", rule: 0.05 },
    616320730871: { type: "percentage", rule: 0.05 },
    616320730321: { type: "percentage", rule: 0.05 },
    616320730789: { type: "percentage", rule: 0.05 },
    616453990623: { type: "percentage", rule: 0.05 },
    616320730338: { type: "percentage", rule: 0.05 },
    662187019321: { type: "percentage", rule: 0.05 },
    616320730611: { type: "percentage", rule: 0.05 },
    616320732882: { type: "percentage", rule: 0.05 },
    662187012490: { type: "percentage", rule: 0.05 },
    662187011585: { type: "percentage", rule: 0.05 },
    662187016504: { type: "percentage", rule: 0.05 },
    662187016467: { type: "percentage", rule: 0.05 },
    662187016474: { type: "percentage", rule: 0.05 },
    662187016498: { type: "percentage", rule: 0.05 },
    662187016481: { type: "percentage", rule: 0.05 },
    616320729509: { type: "percentage", rule: 0.05 },
    662187019987: { type: "percentage", rule: 0.05 },
    662187019994: { type: "percentage", rule: 0.05 },
    691027723708: { type: "percentage", rule: 0.05 },
    "QB:01033918054980": { type: "percentage", rule: 0.05 },
    "QB:01033918054979": { type: "percentage", rule: 0.05 },
    616320730390: { type: "percentage", rule: 0.05 },
    616320732899: { type: "percentage", rule: 0.05 },
    662187012506: { type: "percentage", rule: 0.05 },
    616320730369: { type: "percentage", rule: 0.05 },
    616453990579: { type: "percentage", rule: 0.05 },
    616453990586: { type: "percentage", rule: 0.05 },
    616453990616: { type: "percentage", rule: 0.05 },
    616453990593: { type: "percentage", rule: 0.05 },
    616453990326: { type: "percentage", rule: 0.05 },
    616320730819: { type: "percentage", rule: 0.05 },
    662187019222: { type: "percentage", rule: 0.05 },
    616453990210: { type: "percentage", rule: 0.05 },
    616453982901: { type: "percentage", rule: 0.05 },
    616453990319: { type: "percentage", rule: 0.05 },
    616453990302: { type: "percentage", rule: 0.05 },
    662187011646: { type: "percentage", rule: 0.05 },
    616320730406: { type: "percentage", rule: 0.05 },
    616320730413: { type: "percentage", rule: 0.05 },
    616320730666: { type: "percentage", rule: 0.05 },
    662187011639: { type: "percentage", rule: 0.05 },
    662187017914: { type: "percentage", rule: 0.05 },
    662187017921: { type: "percentage", rule: 0.05 },
    662187017938: { type: "percentage", rule: 0.05 },
    662187017945: { type: "percentage", rule: 0.05 },
    662187017068: { type: "percentage", rule: 0.05 },
    662187016818: { type: "percentage", rule: 0.05 },
    662187016870: { type: "percentage", rule: 0.05 },
    662187016887: { type: "percentage", rule: 0.05 },
    662187016863: { type: "percentage", rule: 0.05 },
    662187017952: { type: "percentage", rule: 0.05 },
    662187017969: { type: "percentage", rule: 0.05 },
    662187017976: { type: "percentage", rule: 0.05 },
    662187017983: { type: "percentage", rule: 0.05 },
    662187016856: { type: "percentage", rule: 0.05 },
    662187016801: { type: "percentage", rule: 0.05 },
    662187016825: { type: "percentage", rule: 0.05 },
    662187016832: { type: "percentage", rule: 0.05 },
    662187016849: { type: "percentage", rule: 0.05 },
    616320732820: { type: "percentage", rule: 0.05 },
  };
}

function allNutsOverages() {
  return {
    616320730505: { type: "percentage", rule: 0.05 },
    616320730260: { type: "percentage", rule: 0.05 },
    616320730277: { type: "percentage", rule: 0.05 },
    616453990333: { type: "percentage", rule: 0.05 },
    662187012360: { type: "percentage", rule: 0.05 },
    616320730345: { type: "percentage", rule: 0.05 },
    616320730253: { type: "percentage", rule: 0.05 },
    616320730352: { type: "percentage", rule: 0.05 },
    616320730581: { type: "percentage", rule: 0.05 },
    616320730284: { type: "percentage", rule: 0.05 },
    616320730376: { type: "percentage", rule: 0.05 },
    616320730925: { type: "percentage", rule: 0.05 },
    662187013268: { type: "percentage", rule: 0.05 },
    662187013275: { type: "percentage", rule: 0.05 },
  };
}

function allWashersOverages() {
  return {
    616320730888: { type: "percentage", rule: 0.05 },
    616453990340: { type: "percentage", rule: 0.05 },
    616320730383: { type: "percentage", rule: 0.05 },
    616320732837: { type: "percentage", rule: 0.05 },
    616320730307: { type: "percentage", rule: 0.05 },
    662187013244: { type: "percentage", rule: 0.05 },
    616320732813: { type: "percentage", rule: 0.05 },
    616320730314: { type: "percentage", rule: 0.05 },
    616320731052: { type: "percentage", rule: 0.05 },
  };
}

function blackOxideOverrages() {
  return {
    662187018805: { type: "percentage", rule: 0.05 },
    662187018812: { type: "percentage", rule: 0.05 },
    662187013336: { type: "percentage", rule: 0.05 },
    662187013312: { type: "percentage", rule: 0.05 },
    662187013343: { type: "percentage", rule: 0.05 },
    662187013350: { type: "percentage", rule: 0.05 },
    662187013329: { type: "percentage", rule: 0.05 },
    662187013275: { type: "percentage", rule: 0.05 },
    662187020730: { type: "percentage", rule: 0.05 },
    // Screw
    662187016252: { type: "percentage", rule: 0.05 },
    691027720127: { type: "percentage", rule: 0.05 },
    662187013220: { type: "percentage", rule: 0.05 },
    662187013237: { type: "percentage", rule: 0.05 },
    662187016245: { type: "percentage", rule: 0.05 },
    662187016238: { type: "percentage", rule: 0.05 },
  };
}

function quickNutOverages() {
  return {
    616320731755: { type: "percentage", rule: 0.05 },
    616320731762: { type: "percentage", rule: 0.05 },
    616320731779: { type: "percentage", rule: 0.05 },
    616320731786: { type: "percentage", rule: 0.05 },
    662187017075: { type: "percentage", rule: 0.05 },
    662187017082: { type: "percentage", rule: 0.05 },
  };
}

function tensionersOverages() {
  return {
    616320732042: { type: "basedOnCount", rule: { add: 1, every: 25 } },
    616320732059: { type: "basedOnCount", rule: { add: 1, every: 25 } },
    616320732066: { type: "basedOnCount", rule: { add: 1, every: 25 } },
    616320732073: { type: "basedOnCount", rule: { add: 1, every: 25 } },
    616453982208: { type: "basedOnCount", rule: { add: 1, every: 25 } },
  };
}

function surfaceMountOverages() {
  return {
    616320732523: { type: "basedOnCount", rule: { add: 1, every: 50 } },
    616320732530: { type: "basedOnCount", rule: { add: 1, every: 50 } },
    616320732547: { type: "basedOnCount", rule: { add: 1, every: 50 } },
    616320732554: { type: "basedOnCount", rule: { add: 1, every: 50 } },
  };
}

function stairWashersOverages() {
  return {
    616453989931: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453989979: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    691027726020: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187017273: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187011783: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187017280: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187016641: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187017297: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187016634: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187017303: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453989962: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453989924: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453989948: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453989900: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731649: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731656: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731625: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731632: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731687: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731694: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731663: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731670: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731724: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731731: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731700: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320731717: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187014111: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187014128: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187014135: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187014142: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187013299: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187013282: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    691027726150: { type: "basedOnCount", rule: { add: 1, every: 40 } },
  };
}

export function totalCableFootage(project) {
  const allRuns = project.canvases.reduce((runs, canvas) => {
    return runs.merge(
      canvas.runs.map((run) => {
        let railDistance = 0;
        const runSettings = getRunSettings(run, project.settings);
        const theSettings = mergeSettings(project.settings, runSettings);

        if (run.stairs && run.stairs.continuousStairs) {
          railDistance = getTotalRailDistanceOfStairsRun(run, canvas);
        } else {
          railDistance = getRailDistance(run, canvas.stairs);
        }

        if (railDistance % 1 === 0 && railDistance % 2 !== 0) {
          // Make distance even for calculating inches.
          railDistance++;
        }

        const distance = calculateRailingAmount(
          roundToNearestInch(railDistance),
          theSettings
        );

        return distance;
      })
    );
  }, Map());

  let totalFootage = 0;

  allRuns.forEach((runDistance) => {
    totalFootage += runDistance;
  });

  return totalFootage;
}

function getTotalPosts(project) {
  const allPosts = project.canvases.reduce((count, canvas) => {
    canvas.runs.forEach((run) => {
      const posts = getPosts(run, project.settings, canvas);

      count += posts.length;
    });
    return count;
  }, 0);

  return allPosts;
}

function passivationSprayOverage() {
  return {
    616320731042: { type: "basedOnTotalCable", rule: { add: 1, every: 2500 } },
    616320731151: { type: "basedOnTotalCable", rule: { add: 1, every: 2500 } },
  };
}

function passivationServiceOverage() {
  return {
    "SERVICE STAINLESS:SVC-Marine-Envir.-PassivationSS": {
      type: "passivationService",
    },
  };
}

function grommetsPVCOverage() {
  return {
    662187013046: { type: "percentage", rule: 0.1 },
    662187013039: { type: "percentage", rule: 0.1 },
    616320732868: { type: "percentageOfUnit", rule: 0.1, unit: 4 },
    616320732875: { type: "percentageOfUnit", rule: 0.1, unit: 4 },
    662187011837: { type: "percentage", rule: 0.1 },
    662187011820: { type: "percentage", rule: 0.1 },
  };
}

function lagsBoltsOverage() {
  return {
    616453990364: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990371: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990388: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990395: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990401: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990418: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990425: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990432: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990449: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990456: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012261: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012278: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012285: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012292: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012308: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012315: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012322: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012339: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012346: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012353: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    691027720110: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012223: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012230: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187019826: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012254: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453982734: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453982741: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320732707: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320730529: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320730536: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320732714: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320732721: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320732745: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320730543: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187011622: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320732738: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320732752: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320732769: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990326: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320730819: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187019222: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990210: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453982901: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990319: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990302: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990463: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990470: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990487: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990494: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990500: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990517: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990524: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990531: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990548: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990555: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990227: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987470: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987463: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987494: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987487: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987500: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187020617: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987524: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987517: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987548: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987531: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987562: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987555: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987579: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012735: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012742: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012759: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012766: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012773: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012780: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012797: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012803: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012810: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012827: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453987586: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187019833: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453991507: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453991514: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453991521: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453991491: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012858: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012834: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187018669: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012865: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
  };
}

function tapconsOverage() {
  return {
    662187019352: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187019345: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187016955: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453982925: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320730956: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320732929: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990357: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616320730970: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    662187012124: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
    616453990296: {
      type: "basedOnCountAfter",
      rule: { add: 1, every: 40, after: 40 },
    },
  };
}

function drillBitOverage() {
  return {
    616320731083: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    616320731090: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    616320733032: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187011752: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187019338: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187017112: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    616320731106: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187017174: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187011738: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187011721: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187011714: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187011707: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187011691: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187012667: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187019307: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    616453991743: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187013961: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187018072: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187011684: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187011677: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    616453989719: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    616453989702: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    616453989726: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
    662187011745: { type: "basedOnPostsCount", rule: { add: 1, every: 20 } },
  };
}

function apexBitOverage() {
  return {
    616320731748: { type: "basedOnPostsCount", rule: { add: 1, every: 40 } },
    616320731847: { type: "basedOnPostsCount", rule: { add: 1, every: 40 } },
  };
}

function brushOnTapeOverage() {
  return {
    616453989269: { type: "basedOnPostsCount", rule: { add: 1, every: 50 } },
  };
}

function plasticScrewCoversOverage() {
  return {
    662187012988: { type: "percentage", rule: 0.15 },
    662187012995: { type: "percentage", rule: 0.15 },
    662187013008: { type: "percentage", rule: 0.15 },
    662187013015: { type: "percentage", rule: 0.15 },
    662187013022: { type: "percentage", rule: 0.15 },
    662187012025: { type: "percentage", rule: 0.15 },
    662187012001: { type: "percentage", rule: 0.15 },
    662187012926: { type: "percentage", rule: 0.15 },
    662187012933: { type: "percentage", rule: 0.15 },
    662187012940: { type: "percentage", rule: 0.15 },
    662187012957: { type: "percentage", rule: 0.15 },
    662187012964: { type: "percentage", rule: 0.15 },
    616320730772: { type: "percentage", rule: 0.15 },
  };
}

function fittingsSleevesOverage() {
  return {
    616320732080: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320732097: { type: "basedOnCount", rule: { add: 1, every: 40 } },
  };
}

function p2pBracketsOverage() {
  return {
    662187011554: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616320729295: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453991576: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453991606: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453991583: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453991620: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187019062: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    662187019055: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453991590: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453991637: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453991569: { type: "basedOnCount", rule: { add: 1, every: 40 } },
    616453991613: { type: "basedOnCount", rule: { add: 1, every: 40 } },
  };
}

function aluminumSpliceOverage() {
  return {
    616320729486: { type: "basedOnCount", rule: { add: 1, every: 10 } },
  };
}

function basePlateBlackSleevesOverage() {
  return {
    662187012971: { type: "basedOnCount", rule: { add: 1, every: 40 } },
  };
}
